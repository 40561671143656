import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { PrivateRouter } from '~~components/Router';

import App from '~~features/App';

// 代替indexRoute的方式 http://stackoverflow.com/questions/42254929/how-to-nest-routes-in-react-router-v4
const Routes = (props) => {
  return (
    <App>
      <Switch>
        <Route
          exact
          path="/leaderboard"
          component={loadable(() => import(/* webpackChunkName: "Leaderboard" */ '../features/Leaderboard'))}
        />
        <Route
          exact
          path="/auth/:formName"
          component={loadable(() => import(/* webpackChunkName: "Auth" */ '../features/Auth'))}
        />
        <PrivateRouter
          exact
          path="/dataList"
          component={loadable(() => import(/* webpackChunkName: "DataList" */ '../features/DataList'))}
        />
        <PrivateRouter
          exact
          path="/player/:playerId"
          component={loadable(() => import(/* webpackChunkName: "Player" */ '../features/Player'))}
        />
        <PrivateRouter
          exact
          path="/player/report/preview"
          component={loadable(() => import(/* webpackChunkName: "ReportPreview" */ '../features/Player/ReportPreview'))}
        />
        <PrivateRouter
          exact
          path="/player/:playerId/report/:reportId"
          component={loadable(() => import(/* webpackChunkName: "ReportPreview" */ '../features/Player/ReportPreview'))}
        />
        <PrivateRouter
          exact
          path="/player/training/calendar"
          component={loadable(() => import(/* webpackChunkName: "TrainingCalendar" */ '../features/TrainingCalendar'))}
        />
        <PrivateRouter
          exact
          path="/player/:playerId/training/:trainingId"
          component={loadable(() => import(/* webpackChunkName: "TrainingCalendar" */ '../features/TrainingCalendar'))}
        />
        <PrivateRouter
          exact
          path="/compare"
          component={loadable(() => import(/* webpackChunkName: "Compare" */ '../features/Compare'))}
        />
        <Route
          exact
          path="/jump"
          component={loadable(() => import(/* webpackChunkName: "Jump" */ '../features/Jump'))}
        />

        <Route path="*" component={loadable(() => import(/* webpackChunkName: "NotFound" */ '../features/NotFound'))} />

        {/* 測試程式遊樂場 */}
        <Route
          exact
          path="/playground"
          component={loadable(() => import(/* webpackChunkName: "Playerground" */ '../features/Playground'))}
        />
      </Switch>
    </App>
  );
};

export default Routes;
