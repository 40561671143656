import React, { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import * as R from 'ramda';
import { IoMdLogIn, IoMdLogOut } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { Button } from '~~components/Buttons';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';
import LogoSVG from '~~static/logo-white-with-ch.svg';
import { USER_ROLE } from '~~features/Const';

const BlueNeumorphism = css`
  background: ${COMMON_COLOR.BRAND_2};
  color: ${COMMON_COLOR.WHITE};
  width: 50%;
  box-shadow: 4px 4px 6px #4d5e6d, -4px -4px 6px #8392a2;
  &:hover {
    box-shadow: 2px 2px 2px #4d5e6d, -2px -2px 2px #8392a2;
  }
`;

const Div = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  background: #385169b3;
  padding: 80px 0 20px;
  transition: transform 0.6s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(150%)')};
  backdrop-filter: blur(3px);
  width: 300px;
  height: 100%;
  box-shadow: 2px 0px 15px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;

  .imgLogo {
    width: 60%;
    margin: 0 0 40px;
    padding: 12px 18px;
    border-radius: 12px;
    background: #617486;
    box-shadow: 2px 2px 2px #4d5e6d, -2px -2px 2px #8392a2;
  }

  .styleButton {
    ${BlueNeumorphism}
  }

  .styleButton,
  a {
    &:not(:last-child) {
      margin: 0 0 20px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${respondSmallerThan.tablet_1024`
    width: 50%;
  `}

  ${respondSmallerThan.mobile_480`
    width: 80%;
  `}
`;

const Sidebar = (props) => {
  const { isOpen, setInMenuOpen, setIsResetPwModalOpen } = props;
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};

  useEffect(() => {
    if (!isOpen) return;

    setIsLogin(!R.isEmpty(userInfo));
  }, [isOpen]);

  function handleLogin() {
    history.push('/auth/login');
  }

  function handleLogout() {
    localStorage.clear();
    history.push('/auth/login');
  }

  function handleRevisePwClick() {
    setIsResetPwModalOpen(true);
    setInMenuOpen(false);
  }

  return (
    <Div isOpen={props.isOpen}>
      <img src={LogoSVG} alt="" className="imgLogo" />
      {isLogin && userInfo.role !== USER_ROLE.PLAYER && (
        <>
          <a href="/dataList">
            <Button>管理列表</Button>
          </a>
          <a href="/compare">
            <Button>層級比較</Button>
          </a>
          <a href="/jump">
            <Button>壓測板</Button>
          </a>
        </>
      )}
      {isLogin ? (
        <>
          <Button onClick={handleRevisePwClick}>變更密碼</Button>
          <Button onClick={handleLogout}>
            登出
            <IoMdLogOut className="icon" />
          </Button>
        </>
      ) : (
        <Button onClick={handleLogin}>
          登入
          <IoMdLogIn className="icon" />
        </Button>
      )}
    </Div>
  );
};

export default Sidebar;
