import ApiService from '~~apis/ApiService';
import { fakeApiHelper } from '../../utils/_commonUtils';
import { reverse } from 'ramda';

const chunkSize = 1024 * 1024 * 2;

const genID = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getChunkAPI = (file, URL) => {
  const ext = reverse(file.name.split('.'))[0];
  const filename = `${new Date().getTime()}-${genID(10)}.${ext}`;
  // const request = new XMLHttpRequest();
  // request.overrideMimeType('application/octet-stream');

  let currentChunkStartByte = 0;
  let currentChunkFinalByte = chunkSize > file.size ? file.size : chunkSize;

  const uploadFile = (callbackCheck, progress) => {
    const formData = new FormData();
    const chunk = file.slice(currentChunkStartByte, currentChunkFinalByte);
    formData.append('video_chunk', chunk, filename);

    ApiService.post(URL, {
      headers: {
        'Content-Range': `bytes ${currentChunkStartByte}-${currentChunkFinalByte}/${file.size}`,
      },
      data: formData,
      timeout: 0,
    })
      .then(({ data }) => {
        const remainingBytes = file.size - currentChunkFinalByte;

        if (currentChunkFinalByte === file.size) {
          const { uniqid, url: fileUrl, path: filePath } = data;
          progress(100);
          callbackCheck({
            uniqid,
            url: fileUrl,
            path: filePath,
            filename,
          });
          return;
        } else if (remainingBytes < chunkSize) {
          currentChunkStartByte = currentChunkFinalByte;
          currentChunkFinalByte = currentChunkStartByte + remainingBytes;
        } else {
          currentChunkStartByte = currentChunkFinalByte;
          currentChunkFinalByte = currentChunkStartByte + chunkSize;
        }

        progress(currentChunkFinalByte / file.size);
        uploadFile(callbackCheck, progress);
      })
      .catch((err) => {
        console.error(err);
        callbackCheck(false);
      });
  };

  return { uploadFile };
};

const debugStatus = {
  uploadPlayerVideo: false,
  uploadTrainingVideo: false,
};

const VideoResource = {
  uploadPlayerVideo: (playerId, file, callback, progress) => {
    if (debugStatus.uploadPlayerVideo) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    const URL = `/media/players/${playerId}/videos`;

    const chunkAPI = getChunkAPI(file, URL);
    chunkAPI.uploadFile(callback, progress);
  },

  uploadTrainingVideo: (trainingID, file, callback, progress) => {
    if (debugStatus.uploadTrainingVideo) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    const URL = `/media/trainings/${trainingID}/videos`;

    const chunkAPI = getChunkAPI(file, URL);
    chunkAPI.uploadFile(callback, progress);
  },

  updateVideo: (playerId, data) => {
    return ApiService.put(`/api/players/${playerId}/videos`, { data });
  },

  getVideoListInfo: (playerId, links) => {
    const query = `?links=${links.map(encodeURIComponent).join('&links=')}`;
    return ApiService.get(`/api/players/${playerId}/videos${query}`);
  },
};

export default VideoResource;
