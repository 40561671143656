import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { BsExclamationCircle } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import { COMMON_COLOR } from '~~styles/_variables';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  ${InputStyle}
  cursor: pointer;

  .fieldLabel {
    &.onTop {
      transform: translate(-2px, -18px) scale(0.85);
      background: ${(props) => props.fieldBgColor};
      padding: 0 6px;
      font-size: 16px;
      /* color: ${COMMON_COLOR.FIELD_FOCUS_LABEL}; */
    }
  }

  .styleSelect {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .placeholder {
    letter-spacing: 1px;
    font-size: 14px;
    color: transparent;
    background: none;
    transition: all 0.5s;
    &.show {
      color: ${(props) => props.fieldColor};
      opacity: 0.5;
      letter-spacing: 1px;
    }
    &.withValue {
      display: none;
    }
  }

  .menu {
    width: 100%;
    border: 1px solid ${COMMON_COLOR.BRAND_3};
    border-radius: 12px;
    background: #fff;
    position: absolute;
    z-index: 2;
    top: 101%;
    user-select: none;
    overflow: hidden auto;
    /* max-height: ${(props) => `${props.menuHeight}px`}; */
    max-height: 222px;
    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 2px solid transparent;
      border-radius: 8px;
    }
    .noOption {
      padding: 16px;
      text-align: center;
    }
    .opt {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      cursor: pointer;
      text-align: left;
      white-space: pre-wrap;
      &.active {
        /* background: #5379a166; */
        background: ${COMMON_COLOR.BRAND_3};
        color: ${COMMON_COLOR.WHITE};
        pointer-events: none;
      }
      &:hover {
        background: #d8dfe7;
      }
    }
  }

  .iconArrow {
    transition: 0.5s ease all;
    &.open {
      transform: rotate(180deg);
      transition: 0.5s ease all;
    }
  }

  &:focus {
    outline: none;
  }
`;

const Select = (props) => {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [menuHeight, setMenuHeight] = useState(272);
  const { fieldLabel, name, value, options, className, onChange, placeholder, errorMsg, disabled, onBlur } = props;
  const triggerField = useRef(null);

  // useEffect(() => {
  //   const node = document.querySelector('#singleOption--0');
  //   if (!node) return;
  //   const height = node.scrollHeight;
  //   setMenuHeight(height * 5 + 4);
  // }, [JSON.stringify(options), isMenuShow]);

  function handleChange(val, e) {
    // console.log(name, '>> handleChange')
    onChange({ [name]: val });
    setIsMenuShow(false);
    e.stopPropagation();
    e.preventDefault();
    triggerField.current.blur();
  }

  function handleClick(e) {
    // console.log(name, '>> handleClick')
    setIsMenuShow((prev) => !prev);
    props.onFocus(name);
    triggerField.current.focus();
    e.preventDefault();
    e.stopPropagation();
  }

  function handleBlur() {
    setIsMenuShow(false);
    onBlur(name);
  }

  function getClassName() {
    let result = ['selectBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv
      ref={triggerField}
      className={getClassName()}
      onClick={handleClick}
      tabIndex="0"
      onBlur={handleBlur}
      menuHeight={menuHeight}
      fieldColor={props.fieldColor}
      fieldBgColor={props.fieldBgColor}
    >
      <div className="styleSelect">
        {R.isEmpty(value) ? '' : value.label}
        <div className={value.value ? 'placeholder withValue' : isMenuShow ? 'placeholder show' : 'placeholder'}>
          {placeholder}
        </div>
      </div>
      {fieldLabel && (
        <label htmlFor={name} className={value.value || isMenuShow ? 'fieldLabel onTop' : 'fieldLabel'}>
          {fieldLabel}
        </label>
      )}

      {/* 下拉箭頭 */}
      <div className="btnFunc">
        <IoIosArrowDown className={isMenuShow ? 'iconArrow open' : 'iconArrow'} />
      </div>

      {/* 下拉選單 */}
      {isMenuShow && (
        <div className="menu">
          {options.length > 0 ? (
            options.map((item, i) => (
              <div
                id={`singleOption--${i}`}
                className={item.value === value.value ? 'opt active' : 'opt'}
                onClick={(e) => handleChange(item, e)}
                key={item.value}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className="noOption">無選項</div>
          )}
        </div>
      )}

      {/* 錯誤訊息 */}
      {errorMsg && (
        <div className="errorBox">
          {/* <BsExclamationCircle className='errorBox--icon' /> */}
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

Select.defaultProps = {
  fieldLabel: '',
  name: '',
  value: '',
  options: [],
  className: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: null,
  errorMsg: '',
  disabled: false,
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
};

export default Select;
