import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Loader from '~~components/Loader';
import ClickOutside from '~~components/ClickOutside';
import HamburgerIcon from '~~components/HamburgerIcon';
import * as CommonActions from '~~redux/CommonActions';
import Notification from '~~components/Notification';
import { respondSmallerThan } from '~~styles/_variables';
import Sidebar from './Sidebar';
import ResetPwModal from './ResetPwModal';
import Header from './Header';

const Div = styled.div`
  position: relative;
  .clickOutside {
    min-height: auto;
    .iconHambuger {
      top: 20px;
      right: 3%;
    }
  }

  > div {
    min-height: ${(props) => props.windowInnerHeight};
    box-sizing: border-box;
    &:not(.clickOutside) {
      padding-top: 5%;
    }
  }

  ${respondSmallerThan.tablet_1024`
    .clickOutside{
      .iconHambuger{
        top: 30px;
      }
    }
  `}

  ${respondSmallerThan.mobile_480`
    .clickOutside{
      .iconHambuger{
        top: 25px;
      }
    }
  `}
`;

const App = (props) => {
  const { windowInnerHeight, setWindowInnerHeight } = props;
  const [isMenuOpen, setInMenuOpen] = useState(false);
  const [isResetPwModalOpen, setIsResetPwModalOpen] = useState(false);
  const locationPath = useLocation().pathname;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    setInMenuOpen(false);
  }, [locationPath]);

  function handleResize() {
    setWindowInnerHeight(`${window.innerHeight}px`);
  }

  return (
    <>
      <Div windowInnerHeight={windowInnerHeight} isMenuOpen={isMenuOpen}>
        {locationPath.indexOf('auth') === -1 && (
          <ClickOutside onClickOutside={() => setInMenuOpen(false)}>
            <HamburgerIcon isOpen={isMenuOpen} setIsOpen={setInMenuOpen} />
            <Sidebar isOpen={isMenuOpen} setInMenuOpen={setInMenuOpen} setIsResetPwModalOpen={setIsResetPwModalOpen} />
          </ClickOutside>
        )}
        {locationPath.indexOf('auth') === -1 && <Header />}
        {props.children}
      </Div>
      <Loader isLoading={props.isLoading} />
      <Notification />

      {/* 變更密碼 */}
      <ResetPwModal
        isOpen={isResetPwModalOpen}
        toggleClose={() => setIsResetPwModalOpen(false)}
        setLoaderStatus={props.setLoaderStatus}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.common.isLoading,
  windowInnerHeight: state.common.windowInnerHeight,
});

const mapDispatchToProps = {
  setLoaderStatus: CommonActions.setLoaderStatus,
  setWindowInnerHeight: CommonActions.setWindowInnerHeight,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
