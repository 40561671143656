import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';
import LogoWithChSVG from '~~static/logo-with-ch.svg';
import LogoWhiteWithChSVG from '~~static/logo-white-with-ch.svg';

const Head = styled.header`
  background: ${COMMON_COLOR.WHITE};
  .imgLogo {
    position: absolute;
    width: 120px;
    top: 20px;
    left: 4%;
  }

  ${respondSmallerThan.tablet_1024`
    padding: ${({ isLeaderboard }) => (isLeaderboard ? '0' : '6% 12% 4%')};
    .imgLogo{
      top: 30px;
    }
    .title{
      margin: 0 0 3%;
    }
  `}

  ${respondSmallerThan.tablet_880`
    padding: ${({ isLeaderboard }) => (isLeaderboard ? '0' : '6% 8% 8%')};
  `}

  ${respondSmallerThan.mobile_480`
    .imgLogo{
      display: none;
    }
    .title{
      font-size: 30px;
    }
    .conditionZone{
      flex-direction: column;
      >:not(:last-child){
        margin: 0 0 4%
      }
    }
  `}
`;

const Header = (props) => {
  const locationPath = useLocation().pathname;
  const isLeaderboard = locationPath === '/leaderboard';
  return (
    <Head isLeaderboard={isLeaderboard}>
      <a href="/leaderboard">
        <img src={isLeaderboard ? LogoWhiteWithChSVG : LogoWithChSVG} className="imgLogo" alt="" />
      </a>
    </Head>
  );
};

export default Header;
