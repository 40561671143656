import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { WhiteNeumorphism } from '~~styles/_commonStyle';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  user-select: none;
  position: relative;
  .fieldLabel {
    position: absolute;
    transform: scale(0.85);
    top: -10px;
    left: -2px;
    white-space: nowrap;
  }
  .body {
    background: ${COMMON_COLOR.WHITE};
    box-shadow: inset 3px 3px 4px #d1d1d1, inset -3px -3px 4px #ffffff;
    border-radius: 50px;
    height: 32px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-delay: 0.3s;
    transition-duration: 0.5s;
    margin: 15px 0 0;
    font-size: 15px;
    &.active {
      background: ${COMMON_COLOR.BRAND_3};
      box-shadow: inset 3px 3px 4px #5c7288, inset -3px -3px 4px #8cadce;
    }
    .slider {
      ${WhiteNeumorphism(2, 2)}
      width: 45px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      position: absolute;
      transition: 0.5s ease;
    }
  }
`;

const Switch = (props) => {
  const { name, className, fieldLabel, checked, onChange } = props;
  const [switchWidth, setSwitchWidth] = useState(0);
  const [sliderLeft, setSliderLeft] = useState(0);
  const switchRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (switchWidth === 0) {
      handleResize();
      return;
    }
    setSliderLeft(checked ? switchWidth - 45 : 0);
  }, [checked, switchWidth]);

  function handleResize() {
    const width = switchRef.current.clientWidth;
    setSwitchWidth(width);
  }

  function handleChange() {
    onChange({ [name]: !checked });
  }

  function getClassName() {
    let result = ['switchBox'];
    if (className) {
      result.push(className);
    }

    return result.join(' ');
  }
  return (
    <Div ref={switchRef} className={getClassName()}>
      <div className="fieldLabel">{fieldLabel}</div>
      <div className={checked ? 'body active' : 'body'} onClick={handleChange}>
        <div className="slider" style={{ left: sliderLeft }}>
          {checked ? '是' : '否'}
        </div>
      </div>
    </Div>
  );
};

Switch.defaultProps = {
  name: '',
  className: '',
  fieldLabel: '',
  checked: false,
  onChange: () => {},
};

export default Switch;
