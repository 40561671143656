import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as R from 'ramda';
import * as CommonActions from '~~redux/CommonActions';
import ApiService from '~~apis/ApiService';

const PrivateRouter = (props) => {
  const { component: Component, ...restProps } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};

  const [tokenRefreshed, setTokenRefreshed] = useState(false);

  useEffect(() => {
    props.setLoaderStatus(true);
    ApiService.refreshToken().finally(() => {
      props.setLoaderStatus(false);
      setTokenRefreshed(true);
    });
  }, []);

  return (
    <Route
      {...restProps}
      render={(renderProps) => {
        return !R.isEmpty(userInfo) ? (
          !tokenRefreshed ? (
            <></>
          ) : (
            <Component {...renderProps} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { fromPath: renderProps.location },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setLoaderStatus: CommonActions.setLoaderStatus,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrivateRouter);
