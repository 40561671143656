import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getTrainingDrills: false,
};

const TrainingResource = {
  getTrainingDrills: (trainingType) => {
    // console.log('Resource getTrainingDrills -----', data)
    if (debugStatus.getTrainingDrills) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/training/drills?training_type=${trainingType}`);
  },
};

export default TrainingResource;
