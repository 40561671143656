import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';

const StyledBurger = styled.div`
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'absolute')};
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: transform 0.4s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(20px)' : 'translateX(0px)')};
  padding: 5px 10px;

  div {
    width: 26px;
    height: 3px;
    background: ${({ isOpen, isLeaderboard }) =>
      isOpen ? COMMON_COLOR.WHITE : isLeaderboard ? COMMON_COLOR.BOARD_BLUE_1 : COMMON_COLOR.BRAND_2};
    transition: all 0.4s linear;
    position: relative;
    transform-origin: 1px;
    border-radius: 4px;
    &:not(:last-child) {
      margin: 0 0 6px;
    }

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'translateY(0px) rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      width: ${({ isOpen }) => (isOpen ? '26px' : '20px')};
      transform: ${({ isOpen }) => (isOpen ? 'translateY(8px) rotate(-45deg)' : 'rotate(0)')};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'translateY(0px) rotate(-45deg)' : 'rotate(0)')};
    }
  }

  ${respondSmallerThan.tablet_1024`
    transform: unset
  `}
`;

const HamburgerIcon = (props) => {
  const { isOpen, setIsOpen } = props;
  const locationPath = useLocation().pathname;
  const isLeaderboard = locationPath === '/leaderboard';

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen((prev) => !prev);
  }

  return (
    <StyledBurger
      className="iconHambuger"
      isOpen={isOpen}
      isLeaderboard={isLeaderboard}
      onClick={(e) => handleClick(e)}
    >
      <div />
      <div />
      {/* <div /> */}
    </StyledBurger>
  );
};

export default HamburgerIcon;
